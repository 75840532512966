import { template as template_c49fe4fa70cd4044bacffc0a0a36bb3d } from "@ember/template-compiler";
const FKText = template_c49fe4fa70cd4044bacffc0a0a36bb3d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
